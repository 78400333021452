<script lang="ts" setup>
import {
  Alert as AAlert,
  Button as AButton,
  Form as AForm,
  Input as AInput,
  InputNumber as AInputNumber,
  Radio as ARadio,
  Select as ASelect,
  Spin as ASpin,
  Switch as ASwitch,
} from 'ant-design-vue'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useAlertStore } from '~/store/alert'
import { useAclStore } from '~/store/acl'

const props = defineProps({
  defaultUsername: {
    default: '',
    type: String,
  },
  defaultPassword: {
    default: '',
    type: String,
  },
  title: {
    default: 'Login',
    type: String,
  },
})
const AInputGroup = AInput.Group
const ARadioGroup = ARadio.Group
const ASelectOption = ASelect.Option
const AFormItem = AForm.Item
const ARadioButton = ARadio.Button

const username = ref('')
const password = ref('')
const loading = ref(false)
const submitting = ref(false)
const submitted = ref(false)
const verifyEmail = ref('')
const verifyShow = ref(false)

const $acl = useAclStore()
const $alert = useAlertStore()
const $router = useRouter()
const $route = useRoute()

const resendVerify = async () => {
  verifyShow.value = true
}
// ...mapActions('login', ['login', 'logout']),
const handleSubmit = async () => {
  submitted.value = true
  $alert.clear()
  // console.log('???', username, password)

  if (!username.value || !password.value) {
    $alert.setError('please enter username and password')
    // this.$store.dispatch('alert/error', 'please enter username and password')
    return
  }
  try {
    const user = await $acl.loginEmail(username.value, password.value)
    if (user === null || !user?.uid) {
      throw new Error('Invalid username/password')
    }
    console.log(
      `successfully logged in with id: ${user?.uid}`,
      user,
      $acl?.currentUser?.customData,
    )
    const cartSessionToken = $route.query._ct as string
    await $acl.loadCart(cartSessionToken)
    // const res = await this.login({ username, password })
    // console.log("login good", res)
    if ($route.query?.redirect) {
      return $router.push($route.query.redirect as string)
    }
    $router.push('/')
  }
  catch (e: any) {
    console.error('login failed', e)
    $alert.setError(e.message)
  }
  submitted.value = false
}
</script>

<template>
  <div>
    <AForm layout="vertical" method="post" name="form3" @submit.prevent="handleSubmit">
      <AFormItem label="Email">
        <AInput v-model:value="username" type="text"></AInput>
      </AFormItem>

      <AFormItem label="Password">
        <AInput v-model:value="password" type="password" autocomplete="false"></AInput>
      </AFormItem>

      <AFormItem>
        <AButton type="primary" html-type="submit" class="login-button w-full" :loading="loading">Login</AButton>
      </AFormItem>
    </AForm>
    <br />

    <div>
      Forgot your password?
      <AButton class="ml-2" @click="$router.push('/login/reset')">Reset password</AButton>
    </div>

    <br />
  </div>
</template>

<style lang="scss"></style>
